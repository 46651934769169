import React, { Component } from 'react';
import emailjs from '@emailjs/browser';
import './CSS/Contato.css';
import EmailSender from './DATA/EmailSender.json';

import Navbar from '../Components/Navbar';

export class Contato extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       fName: null,
       fEmail: null,
       fPhone: null,
       fSubject: null,
       fMessage: null
    }
  }

  handleChange = (e) => {
    e.preventDefault();

    this.setState({
        [e.target.name]: e.target.value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(EmailSender.serviceKey, EmailSender.contato.templateKey, document.getElementById('f-form'), EmailSender.publicKey)
      .then((result) => {
          alert(result.text);

          const inputs = document.querySelectorAll('#f-form>input');
          inputs.forEach(i => {
            i.value = '';
          });

          const txtArea = document.getElementById('f-txt-area');
          txtArea.value = '';
      }, (error) => {
          alert(error.text);
      });
  }

  render() {
    return (
      <div className='ctPageContainer'>
        <Navbar />

        <div className='ctContainer'>
          <div className='ctFormContainer'>
            <form id='f-form' onSubmit={this.handleSubmit}>
              <label><b>Nome: <span className='ctAsteristic'>*</span></b></label>
              <input className='ctFormField' type="text" name="fName" placeholder='Seu nome' onChange={this.handleChange} required/>
              <label><b>E-mail: <span className='ctAsteristic'>*</span></b></label>
              <input className='ctFormField' type="email" name="fEmail" placeholder='email@domain.com' onChange={this.handleChange} required/>
              <label><b>Contato:</b></label>
              <input className='ctFormField' type="text" name="fPhone" placeholder='+5581988888888' onChange={this.handleChange}/>
              <label><b>Assunto: <span className='ctAsteristic'>*</span></b></label>
              <input className='ctFormField' type="text" name="fSubject" placeholder='Assunto da mensagem' onChange={this.handleChange} required/>
              <label><b>Mensagem: <span className='ctAsteristic'>*</span></b></label>
              <textarea id='f-txt-area' className='ctFormField ctFormTextArea' type="text" name="fMessage" placeholder='Sua mensagem' onChange={this.handleChange} required/>
              <label />
              <input className='ctSubmitButton' type="submit" value="Enviar"/>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Contato;