import React, { Component } from 'react';
import './CSS/Gastronomia.css';
import Navbar from '../Components/Navbar';

import Fernanda from '../Assets/Gastronomia/fernanda.png';
import LogoPaiol from '../Assets/Gastronomia/logo-paiol.png';

export class Gastronomia extends Component {

  render() {
    return (
      <div id='gas' className='gasPageContainer'>
        <Navbar />
        
        <div className='gasContainer'>
          <div className='gasContent'>
            <div className='gasText'>
              <p>
                Honrando as tradições de Fernando de Noronha, a 
                proposta gastronômica do Paiol tem como ponto de 
                partida o elemento fogo. A inspiração é o hábito 
                local dos noronhenses na preparação do peixe na 
                fogueira.
              </p>
              <p>
                O cardápio, comandado pela Chef Fernanda Wiethaeuper, 
                traz a força e a riqueza nordestina com toque 
                internacional, indo do Mediterrâneo à Itália, mas 
                sempre reforçando a inspiração local. 
              </p>
            </div>

            <div className='gasPicsContainer'>
              <div className='fernandaContainer'>
                <img src={Fernanda} alt='Fernanda Wiethaeuper' />
              </div>
              <div className='paiolContainer'>
                <img src={LogoPaiol} alt='Paiol' />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Gastronomia;