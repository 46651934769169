import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import './App.css';

import Home from './Pages/Home';
import ForteNoronha from './Pages/ForteNoronha';
import Museu from './Pages/Museu';
import Gastronomia from './Pages/Gastronomia';
import CardapioPaiol from './Pages/CardapioPaiol';
import Experiencias from './Pages/Experiencias';
import Cerimonias from './Pages/Cerimonias';
import Sustentabilidade from './Pages/Sustentabilidade';
import CadastroIlheus from './Pages/CadastroIlheus';
import Contato from './Pages/Contato';
import EmBreve from './Pages/EmBreve';
import Erro404 from './Pages/Erro404';

const App = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      // isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      // metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >

        <Router>
          <Routes>
            <Route exact path='/' element={ <Home /> } />
            <Route exact path='/o-forte' element={ <ForteNoronha /> } />
            <Route exact path='/museu' element={ <Museu /> } />
            <Route exact path='/gastronomia' element={ <Gastronomia /> } />
            <Route exact path='/gastronomia/cardapio-paiol' element={ <CardapioPaiol /> } />
            <Route exact path='/experiencias' element={ <Experiencias /> } />
            <Route exact path='/cerimonias' element={ <Cerimonias /> } />
            <Route exact path='/sustentabilidade' element={ <Sustentabilidade /> } />
            <Route exact path='/cadastro-noronhenses' element={ <CadastroIlheus /> } />
            <Route exact path='/contato' element={ <Contato /> } />

            <Route exact path='/em-breve' element={ <EmBreve /> } />
            
            <Route path='*' element={ <Erro404 /> } />
          </Routes>
        </Router>

      </CacheBuster>
  )
}

export default App;
