import React, { Component } from 'react';
import './CSS/Sustentabilidade.css';
import Navbar from '../Components/Navbar';

import TesouroPic from '../Assets/Sustentabilidade/tesouro-pic.png';

export class Sustentabilidade extends Component {

  render() {
    return (
      <div id='sus' className='susPageContainer'>
        <Navbar />

        <div className='susContainer'>
          <div className='susContent'>
            <div className='susPresContainer'>
              <div className='tesouroPicContainer'>
                <img src={TesouroPic} alt='Tesouro Verde' />
              </div>

              <div className='susTitle'>Sustentabilidade</div>
              <div className='susText'>
                  Forte Noronha é carbono neutro. A iniciativa chancela a 
                  fortaleza como o primeiro equipamento de Fernando de 
                  Noronha e primeiro patrimônio tombado a neutralizar 
                  100% as emissões de carbono no Brasil. Com o selo 
                  “Tesouro Verde”, o Forte Noronha dá um importante 
                  passo em direção à gestão sustentável da Ilha.
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Sustentabilidade;