import React, { Component } from 'react';
import './CSS/ForteNoronha.css';
import Navbar from '../Components/Navbar';

export class ForteNoronha extends Component {

  render() {
    return (
      <div id='fn' className='fnPageContainer'>
        <Navbar />

        <div className='fnBaseContainer'>
          <div className='fnContainer'>
            <div className='fnContent1'>
              <div className='fnPresContainer'>
                <div className='fnPresContent'>
                  <div className='fnTitle'>Forte Noronha.</div>
                  <div className='fnSubTitle'>A sua experiência na ilha começa aqui.</div>
                </div>

                <div className='fnTopics'>
                  <ul>
                    <li>Museu imersivo e a céu aberto</li>
                    <li>Visão panorâmica da ilha</li>
                    <li>Cultura e entretenimento</li>
                    <li>Cerimônias e eventos</li>
                    <li>Gastronomia viva @paiolnoronha</li>
                    <li>Pôr do sol com vista 360° da ilha</li>
                       <li>Sustentabilidade</li>
                <li>DJ Ially </li>
              </ul>
                </div>
              </div>
            </div>

            <div className='fnContent2'>
              <div className='patrContainer'>
                <div className='patrTitle'>Patrimônio Histórico</div>

                <div className='patrTextContainer'>
                    <p className='patrSubTitle'>Construção e posição estratégica</p>
                    <p className='patrText'>
                      O Forte de Nossa Senhora dos Remédios foi construído em 1737 
                      sob o reinado de João V de Portugal. Com a sua posição 
                      dominante sobre o ancoradouro na baía de Santo Antônio, 
                      constituiu-se na principal estrutura de defesa da Ilha e do 
                      arquipélago.
                    </p>
                </div>

                <div className='patrTextContainer'>
                    <p className='patrSubTitle'>Planta</p>
                    <p className='patrText'>
                      De planta poligonal orgânica e adaptada ao terreno, o 
                      conjunto do forte possui uma área de 6.300m2, erguendo-se a 
                      45m acima do nível do mar.
                    </p>
                </div>

                <div className='patrTextContainer'>
                    <p className='patrSubTitle'>Patrimônio Histórico</p>
                    <p className='patrText'>
                      Tombado pelo IPHAN, o Forte recebeu recentemente um 
                      investimento de mais de 11 milhões de reais destinados à 
                      reforma e reestruturação.
                    </p>
                </div>
              </div>
            </div>

            <div className='fnContent3'>
              <div className='ecoContainer'>
                <div className='ecoTitle'>Um ecossistema</div>

                <div className='ecoText'>
                    <ul className='ecoList'>
                      <li>Museu interativo e museu a céu aberto</li>
                      <li>Auditório e sala multifuncional para eventos e convenções</li>
                      <li>Shows, eventos e exposições</li>
                      <li>Restaurante e bar</li>
                      <li>Mirante</li>
                      <li>Projetos socioambientais</li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForteNoronha;