import React, { Component } from 'react';
import './CSS/Experiencias.css';
import Navbar from '../Components/Navbar';

import ExpPics from '../Assets/Experiencias/exp-pics.png';

export class Experiencias extends Component {

  render() {
    return (
      <div id='exp' className='expPageContainer'>
        <Navbar />

        <div className='expContainer'>
          <div className='expContent'>
            <div className='expPresContainer'>
              <div className='expTitle'>Sunset Forte</div>
              <div className='expText'>
                Um pôr do sol exuberante, com 
                vista 360° da Ilha. Tudo ao som 
                de música e com muito astral.
              </div>
            </div>

            <div className='expPicsContainer'>
              <img src={ExpPics} alt='Fotos' />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Experiencias;