import React, { Component } from 'react';
import './CSS/EmBreve.css';
import Navbar from '../Components/Navbar';

export class EmBreve extends Component {

  goBack = () => {
    window.location.href = './';
  }

  render() {
    return (
      <div className='ebPageContainer'>
        <Navbar />

        <div className='ebContainer'>
          <div>
            <span className='ebTitle'>Em Breve</span>

            <div className='ebBackButton' onClick={this.goBack}>Voltar</div>
          </div>
        </div>
      </div>
    )
  }
}

export default EmBreve;