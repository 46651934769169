import React, { Component } from 'react';
import './CSS/HomeVideo.css';

import PresVideo from '../Assets/video.mp4';
import PresVideoTm from '../Assets/video-tm.mp4';

export class HomeVideo extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            bgV: null,
            vWidth: null,
            vHeight: null
        }
    }

    componentDidMount() {
        if (window.innerWidth > 800) {
            this.setState({ bgV: PresVideo });
        } else if (window.innerWidth <= 800) {
            this.setState({ bgV: PresVideoTm });
        }
    }

    setMuted = () => {
        var v = document.getElementById("hv");
        var mButton = document.getElementById("hv-mute-button");

        if (!v.muted) {
            mButton.classList.remove('fa-volume-high');
            mButton.classList.add('fa-volume-xmark');

            v.muted = true;
        } else if (v.muted) {
            mButton.classList.remove('fa-volume-xmark');
            mButton.classList.add('fa-volume-high');

            v.muted = false;
        }
    }

    hideLoader = () => {
        document.getElementById("hv-loader").style.display = 'none';
    }

    render() { 
      return (
        <div className='hvPageContainer'>
            <div className='hvContainer'> <video id='hv' src={this.state.bgV} autoPlay playsInline muted loop disableRemotePlayback={true} onCanPlayThrough={this.hideLoader} /> </div>
            
            <div id='hv-mute-button' className='fa-solid fa-volume-xmark' onClick={this.setMuted} />

            <div id='hv-loader' />
        </div>
      )
    }
}

export default HomeVideo;