import React, { Component } from 'react';
import emailjs from '@emailjs/browser';
import './CSS/CadastroIlheus.css';
import EmailSender from './DATA/EmailSender.json';

import Navbar from '../Components/Navbar';

export class CadastroIlheus extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       fName: null,
       fRG: null,
       fCPF: null,
       fBDate: null,
       fType: 'Morador',
       fResNumber: null,
       fCredNumber: null,
       fValidity: null
    }
  }

  handleChange = (e) => {
    e.preventDefault();

    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleRadio = (e) => {
    if (this.state.fType === 'Morador') {
      document.getElementById('f-c-credor').style.display = 'block';
      document.getElementById('f-c-resident').style.display = 'none';
    } else if (this.state.fType === 'Prestador') {
      document.getElementById('f-c-resident').style.display = 'block';
      document.getElementById('f-c-credor').style.display = 'none';
    }

    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(EmailSender.serviceKey, EmailSender.cadastro.templateKey, document.getElementById('f-c-form'), EmailSender.publicKey)
      .then((result) => {
          alert(result.text);

          const inputs = document.querySelectorAll('#f-c-form>input');
          inputs.forEach(i => {
            i.value = '';
          });
      }, (error) => {
          alert(error.text);
      });
  }

  render() {
    return (
      <div className='ciPageContainer'>
        <Navbar />

        <div className='ciContainer'>
          <div className='ciPresContainer'>
            <p className='ciPresTitle'>Noronhenses,</p>
            <p className='ciPresText'>preencham o cadastro e garanta acesso livre ao Forte Noronha. *</p>
            <p className='ciPresTextObs'>*Em eventos privados e shows com bilheteria a gratuidade não se aplica.</p>
          </div>

          <div className='ciFormContainer'>
            <form id='f-c-form' onSubmit={this.handleSubmit}>
              <label><b>Nome: <span className='ciAsteristic'>*</span></b></label>
              <input className='ciFormField' type="text" name="fName" placeholder='Seu nome' onChange={this.handleChange} required />
              <label><b>RG: <span className='ciAsteristic'>*</span></b></label>
              <input className='ciFormField' type="text" minLength={7} name="fRG" placeholder='9.999.999' onChange={this.handleChange} required />
              <label><b>CPF: <span className='ciAsteristic'>*</span></b></label>
              <input className='ciFormField' type="text" minLength={11} name="fCPF" placeholder='999.999.999-99' onChange={this.handleChange} required />
              <label><b>Data de Nascimento: <span className='ciAsteristic'>*</span></b></label>
              <input className='ciFormField' type="text" name="fBDate" placeholder='31/12/1990' onChange={this.handleChange} required />

              <div className='ciFormChoose'>
                <label><b>Escolha o tipo: <span className='ciAsteristic'>*</span></b></label>
                <input type='radio' name='fType' value='Morador' checked={this.state.fType === 'Morador'} onChange={this.handleRadio} /><span>Morador</span>
                <input type='radio' name='fType' value='Prestador' checked={this.state.fType === 'Prestador'} onChange={this.handleRadio} /><span>Prestador</span>
              </div>
              
              <div id='f-c-resident' className='ciResidentContainer'>
                <label><b>Nº da Carteira: <span className='ciAsteristic'>*</span></b></label>
                <input className='ciFormField' type="text" name="fResNumber" placeholder='Carteira do Morador' onChange={this.handleChange} />
              </div>

              <div id='f-c-credor' className='ciCredorContainer'>
                <label><b>Nº da TPA: <span className='ciAsteristic'>*</span></b></label>
                <input className='ciFormField' type="text" name="fCredNumber" placeholder='Número da TPA' onChange={this.handleChange} />
                <label><b>Validade: <span className='ciAsteristic'>*</span></b></label>
                <input className='ciFormField' type="text" name="fValidity" placeholder='12/23' onChange={this.handleChange} />
              </div>

              <label />
              <input className='ciSubmitButton' type="submit" value="Enviar"/>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default CadastroIlheus;