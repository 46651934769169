import React, { Component } from 'react';
import Navbar from '../Components/Navbar';
import './CSS/Erro404.css';

export class Erro404 extends Component {

  goBack = () => {
    window.location.href = './';
  }

  render() {
    return (
      <div className='errPageContainer'>
        <Navbar />

        <div className='errContainer'>
          <div>
            <p className='errTitle'>Erro 404</p>
            <p className='errText'>
              Página não encontrada.
            </p>

            <div className='errBackButton' onClick={this.goBack}>Voltar</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Erro404;