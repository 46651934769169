import React, { Component } from 'react';
import './CSS/Cerimonias.css';
import Navbar from '../Components/Navbar';

import CerPics from '../Assets/Cerimonias/cer-pics.png';
import CerPics2 from '../Assets/Cerimonias/cer-pics-2.png';

export class Cerimonias extends Component {

  render() {
    return (
      <div id='cer' className='cerPageContainer'>
        <Navbar />

        <div className='cerContainer'>
          <div className='cerContent'>
            <div className='cerPresContainer'>
              <div className='cerTitle'>Cerimônias</div>
              <div className='cerText'>
                <p>
                  Imagine casar, batizar um filho ou renovar 
                  os votos numa igrejinha do século XVIII, 
                  em um Forte tombado e na Ilha que é 
                  patrimônio da UNESCO?!
                </p>
                <p>
                  Não precisa mais ficar imaginando. A capela 
                  do Forte Noronha conta com a gerência da 
                  Arquidiocese de Olinda e do Recife, 
                  possibilitando celebração de cerimônias 
                  religiosas.
                </p>
              </div>
            </div>
          </div>

          <div className='cerContent2'>
            <div className='cerPicsContainer'>
              <img src={CerPics} alt='Fotos' />
              <img src={CerPics2} alt='Fotos 2' />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Cerimonias;