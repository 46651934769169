import React, { Component } from 'react';
import './CSS/Home.css';

import Navbar from '../Components/Navbar';
import HomeVideo from './HomeVideo';

export class Home extends Component {
  render() { 
    return (
      <div id='inicio' className='hPageContainer'>
        <Navbar />
        <HomeVideo />
      </div>
    )
  }
}

export default Home;