import React, { Component } from 'react';
import './CSS/Museu.css';
import Navbar from '../Components/Navbar';

import IDG from '../Assets/Museu/logo-idg.png';
import Pic from '../Assets/Museu/seq-img.png';
import Pic2 from '../Assets/Museu/seq-img-2.png';
import Pic3 from '../Assets/Museu/seq-img-3.png';
import Pic4 from '../Assets/Museu/seq-img-4.png';

export class Museu extends Component {
  
  render() {
    return (
      <div id='mus' className='musPageContainer'>
        <Navbar />

        <div className='musContainer'>
          <div className='musContent1'>
            <div className='musPresContainer'>
              <div className='musTitle'>Museu Imersivo</div>
              <div className='musSubTitle'>
                Conheça a história da ilha através de 
                uma experiência imersiva e de uma visita 
                guiada pelo nosso museu a céu aberto.
              </div>
            </div>
          </div>

          <div className='musContent2'>
            <div className='idgContainer'>
              <div className='idgImgContainer'>
                <img src={IDG} alt='IDG' />
              </div>

              <div className='idgText'>
                Com um plano museológico elaborado pelo IDG, 
                gestor do renomado e premiado Museu do Amanhã e 
                de outros equipamentos culturais, o Forte Noronha 
                tem um museu imersivo contando toda a história do 
                surgimento da Ilha, além de visitas guiadas a céu 
                aberto, contemplando toda a biodiversidade do 
                arquipélago de Fernando de Noronha.
              </div>
            </div>

            <div className='imgSeqContainer'>
              <div> <img src={Pic} alt='Foto 1' /> </div>
              <div> <img src={Pic2} alt='Foto 2' /> </div>
              <div> <img src={Pic3} alt='Foto 3' /> </div>
              <div> <img src={Pic4} alt='Foto 4' /> </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Museu;