import React, { Component } from 'react';
import './CSS/Navbar.css';

import Logo from '../Assets/logo-fn.png';

// eslint-disable-next-line
// import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

const instaLink = 'https://www.instagram.com/fortenoronha/';
const linkedinLink = 'https://www.linkedin.com/company/forte-noronha/';
const ytLink = 'https://www.youtube.com/channel/UC_cNwDpRU34FF43saYnevsw';
const ingLink = 'https://ingressos.fortenoronha.com.br/ingressos.html';

export class Navbar extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       openBM: false
    }
  }

  setBurgerOpen = () => {
    const navItemsCt = document.getElementById('nic');
    const burgerLines = document.querySelectorAll('.burger div');

    const navLinks = document.querySelectorAll('.navItemsContainer>div');

    navLinks.forEach((link, index) => {
        if (link.style.animation) {
            link.style.animation = '';
        } else {
            link.style.animation = `navLinkFade 0.3s ease forwards ${index / 9 + 0.3}s`;
        }
    });

    if (!this.state.openBM) {
        navItemsCt.style.display = 'flex';
        navItemsCt.classList.add('open');

        burgerLines.forEach(line => {
            line.classList.add('open');
        });
        
        this.setState({
            openBM: true
        });
    } else {
        navItemsCt.style.display = 'none';
        navItemsCt.classList.remove('open');

        burgerLines.forEach(line => {
            line.classList.remove('open');
        });
        
        this.setState({
            openBM: false
        });
    }
  }

  // goToSection = (secId, offset) => {
  //   scroller.scrollTo(secId, {
  //     offset: offset,
  //     duration: 800,
  //     delay: 0,
  //     smooth: 'easeInOutQuart'
  //   });
  // }

  render() {
    return (
      <div className='navbar'>
        <div className='leftLogoContainer'>
          <a href='./'>
            <img className='leftLogo' src={Logo} alt='Logo' />
          </a>
        </div>

        <div className='burgerContainer'>
          <div className='burger' onClick={this.setBurgerOpen}>
            <div className='b-l1' />
            <div className='b-l2' />
            <div className='b-l3' />
          </div>
        </div>

        <div id='nic' className='navItemsContainer'>
          <div> <a href='/o-forte'>O Forte</a> </div>
          <div> <a href='/museu'>Museu</a> </div>
          <div> <a href='/gastronomia'>Gastronomia</a> </div>
          <div> <a href='/experiencias'>Experiências</a> </div>
          <div> <a href={ingLink}>Compre aqui<br/>seu Passaporte</a> </div>

          <div className='middleLogoContainer'>
            <a href='./'>
              <img className='middleLogo' src={Logo} alt='Logo' />
            </a>
          </div>

          <div> <a href='/cerimonias'>Cerimônias<br/>no Forte</a> </div>
          <div> <a href='/sustentabilidade'>Sustentabilidade</a> </div>
          <div> <a href='/cadastro-noronhenses'>Cadastro<br/>Noronhenses</a> </div>
          <div> <a href='/contato'>Contato</a> </div>

          <div className='socialContainer'>
            <div>
              <a href={instaLink} target='_blank' rel='noopener noreferrer'> 
                <span className="fa-stack fa-2x">
                  <i className="fa-solid fa-circle fa-stack-2x sIconBG"></i>
                  <i className="fa-brands fa-instagram fa-stack-1x fa-inverse sIcon"></i>
                </span>
              </a>
            </div>

            <div>
              <a href={linkedinLink} target='_blank' rel='noopener noreferrer'> 
                <span className="fa-stack fa-2x">
                  <i className="fa-solid fa-circle fa-stack-2x sIconBG"></i>
                  <i className="fa-brands fa-linkedin-in fa-stack-1x fa-inverse sIcon"></i>
                </span>
              </a>
            </div>

            <div>
              <a href={ytLink} target='_blank' rel='noopener noreferrer'> 
                <span className="fa-stack fa-2x">
                  <i className="fa-solid fa-circle fa-stack-2x sIconBG"></i>
                  <i className="fa-brands fa-youtube fa-stack-1x fa-inverse sIcon"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Navbar;