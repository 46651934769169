import React, { Component } from 'react';
import './CSS/CardapioPaiol.css';

import Cardapio from '../Assets/cardapio.pdf';

export class CardapioPaiol extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       isIOS: false
    }
  }

  componentDidMount() {
    this.checkIOS();
  }

  checkIOS = () => {
    var ua = navigator.userAgent;
    
    const cardapioAndroid = document.getElementById("cardapio-android");
    const cpContainer = document.getElementById("cp-container");

    if (/iPhone|iPad|iPod/i.test(ua)) {
      cardapioAndroid.style.display = 'none';
      cpContainer.style.display = 'flex';
      this.setState({ isIOS: true });
    }
  }

  downloadCardapio = () => {
    window.location.href = Cardapio;
  }

  render() {
    return (
      <div className='cpPageContainer'>
        <iframe id='cardapio-android' className='cpPDF' title='cardapio-paiol' src={`https://docs.google.com/a/fortenoronha/viewer?url=https://fortenoronha.com.br` + Cardapio + `&embedded=true`} width="100%" height="100%">
          Seu browser não suporta este plugin. Faça o download do documento <a href={Cardapio}>aqui</a>.
        </iframe>

        <div id='cp-container' className='cpContainer'>
          <p>Baixe aqui o nosso cardápio</p>
          <div className='cpDownloadButton' onClick={this.downloadCardapio}>Download</div>
        </div>
      </div>
    )
  }
}

export default CardapioPaiol;